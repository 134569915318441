import { createSlice } from "@reduxjs/toolkit"
import shopifyClient from "@utils/shopifyClient"
import getCurrencyCode from "@utils/getCurrencyCode"

const currencyCode = getCurrencyCode()
const countryCode = process.env.GATSBY_LOCALE.toUpperCase()

const cartFragment = `
  fragment CartFields on Cart {
    id
    checkoutUrl
    note
    attributes {
      key
      value
    }    
    cost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
    }
    lines(first: 250) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                url
                altText
              }
              compareAtPrice {
                currencyCode
                amount
              }
              price {
                amount
                currencyCode
              }
              product {
                title
                handle
                id
              }
            }
          }
        }
      }
    }
  }
`

const app = createSlice({
  name: "cart",
  initialState: {
    cart: null,
    locale: process.env.GATSBY_LOCALE,
    currencyCode,
    localisedLineItems: {},
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
    setLocalisedLineItem: (state, action) => {
      const { productId, localisedData, quantity } = action.payload

      if (quantity <= 0) {
        delete state.localisedLineItems[productId]
      } else {
        state.localisedLineItems[productId] = localisedData
      }

      localStorage.setItem(
        `shopify_localised_line_items_${process.env.GATSBY_LOCALE}`,
        JSON.stringify(state.localisedLineItems),
      )
    },
  },
})

/**
 * Fetches and restores an existing cart from local storage, or creates a new one
 * if no cart exists.
 *
 * @returns {Promise<void>}
 */
export const initializeCart = () => async dispatch => {
  const isBrowser = typeof window !== "undefined"

  /**
   * Helper function to get a local storage item
   *
   * @param {string} key
   * @returns {string|null}
   */
  const getLocalStorageItem = key =>
    isBrowser ? localStorage.getItem(key) : null

  /**
   * Helper function to create a new cart
   *
   * @returns {Promise<void>}
   */
  const initCreateCart = async () => {
    try {
      await createCart(dispatch)
    } catch (error) {
      console.error("Error creating new cart:", error)
    }
  }

  /**
   * Helper function to fetch an existing cart
   *
   * @param {string} cartID
   * @returns {Promise<void>}
   */
  const fetchAndRestoreExistingCart = async cartID => {
    try {
      const { data } = await shopifyClient.request(
        `
        query ($cartId: ID!, $country: CountryCode, $language: LanguageCode)
        @inContext(country: $country, language: $language) {
          cart(id: $cartId) {
            ...CartFields
          }          
        }
        ${cartFragment}
        `,
        {
          variables: { cartId: cartID, country: countryCode },
        },
      )

      if (!data.cart || data.cart.id == null) {
        await initCreateCart()
        return
      }

      dispatch(
        setState({
          cart: data.cart,
        }),
      )
    } catch (error) {
      console.error("Error fetching existing cart:", error)
      await initCreateCart()
    }
  }

  const existingCartID = getLocalStorageItem(
    `shopify_cart_id_${process.env.GATSBY_LOCALE}`,
  )
  const savedLocalisedLineItems = getLocalStorageItem(
    `shopify_localised_line_items_${process.env.GATSBY_LOCALE}`,
  )

  if (savedLocalisedLineItems) {
    dispatch(
      setState({
        localisedLineItems: JSON.parse(savedLocalisedLineItems),
      }),
    )
  }

  if (!existingCartID) {
    await initCreateCart()
  } else {
    await fetchAndRestoreExistingCart(existingCartID)
  }
}

/**
 * Creates a new cart and sets it in the state and local storage
 *
 * @returns {Promise} A promise that resolves with the result of the mutation
 */
const createCart = async dispatch => {
  const cartCreateMutation = `
    mutation ($input: CartInput!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
      cartCreate(input: $input) {
        cart {
          id
          checkoutUrl
        }
        userErrors {
          message
          code
          field
        }          
      }
    }
  `

  const input = {
    attributes: [
      {
        key: "locale",
        value: process.env.GATSBY_LOCALE,
      },
    ],
    buyerIdentity: {
      countryCode,
    },
  }

  const { data } = await shopifyClient.request(cartCreateMutation, {
    variables: {
      input,
      country: countryCode,
    },
  })

  localStorage.setItem(
    `shopify_cart_id_${process.env.GATSBY_LOCALE}`,
    data.cartCreate.cart.id,
  )

  dispatch(
    setState({
      cart: data.cartCreate.cart,
    }),
  )
}

/**
 * Mutation to add a line item to a cart
 *
 * @param {string} cartId
 * @param {Array} lines
 * @returns {Promise} A promise that resolves with the result of the mutation
 */
const addLineItemMutation = (cartId, lines) => {
  const cartLinesMutation = `
    mutation ($cartId: ID!, $lines: [CartLineInput!]!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {       
          ...CartFields
        }
        userErrors {
          field
          message
        }
      }
    }
    ${cartFragment}      
  `

  return shopifyClient.request(cartLinesMutation, {
    variables: { cartId, lines, country: countryCode },
  })
}

/**
 * Mutation to update a line item
 *
 * @param {string} cartId
 * @param {string} lineId
 * @param {number} quantity
 * @returns {Promise} A promise that resolves with the result of the mutation
 */
const updateLineItemMutation = async (cartId, lineId, quantity) => {
  const cartLinesMutation = `
    mutation ($cartId: ID!, $lines: [CartLineUpdateInput!]!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ...CartFields
        }
        userErrors {
          field
          message
        }
      }
    }
    ${cartFragment}  
  `

  return shopifyClient.request(cartLinesMutation, {
    variables: {
      cartId,
      lines: [
        {
          id: lineId,
          quantity,
        },
      ],
      country: countryCode,
    },
  })
}

/**
 * Checks if the response has user errors
 *
 * @param {Array} userErrors
 * @returns {boolean} If there are user errors
 */
const handleUserErrors = userErrors => {
  if (userErrors && userErrors.length > 0) {
    console.error(
      "User errors:",
      userErrors.map(({ field, message }) => `${field}: ${message}`).join(", "),
    )
    return true
  }
  return false
}

/**
 * Adds line items to the cart.
 * If the line item already exists, its quantity is updated.
 * If the line item doesn't exist, a new one is added.
 *
 * @param {{ cartId: string, product: { shopifyId: string, productTitle: string, price: number, quantity: number, personalisation: string|null } }} props
 * @returns {Promise<void>}
 */
export const addLineItemsToCart =
  ({ cartId, product }) =>
  async (dispatch, getState) => {
    const currentCart = getState().cart
    const existingLineItem = currentCart?.cart?.lines?.edges?.find(
      edge => edge.node.merchandise.id === product.shopifyId,
    )

    if (existingLineItem) {
      const updatedQuantity = Math.max(0, product.quantity)

      try {
        const { data } = await updateLineItemMutation(
          cartId,
          existingLineItem.node.id,
          updatedQuantity,
        )

        if (handleUserErrors(data.cartLinesUpdate.userErrors)) return

        dispatch(setState({ cart: data.cartLinesUpdate.cart }))
      } catch (error) {
        console.error("Error updating line items in cart:", error)
      }
    } else {
      // If line item doesn't exist, add a new one
      const lineItem = {
        merchandiseId: product.shopifyId,
        quantity: product.quantity || 1,
        attributes: product.personalisation
          ? [
              {
                key: "Personalisation",
                value: product.personalisation,
              },
            ]
          : [],
      }

      try {
        const { data } = await addLineItemMutation(cartId, [lineItem])

        if (handleUserErrors(data.cartLinesAdd.userErrors)) return

        dispatch(setState({ cart: data.cartLinesAdd.cart }))
      } catch (error) {
        console.error("Error adding line items to cart:", error)
      }
    }

    const localisedData = {
      productTitle: product.productTitle,
      price: product.price,
    }

    dispatch(
      setLocalisedLineItem({
        productId: product.shopifyId,
        localisedData,
        quantity: product.quantity,
      }),
    )
  }

/**
 * Adds a gift message to the cart as a note attribute.
 *
 * @param {string} cartId - The ID of the cart to update.
 * @param {string} note - The gift message to add to the cart.
 * @returns {Function} A thunk function that dispatches actions.
 */
export const addGiftMessage = (cartId, note) => async dispatch => {
  const updateGiftMessageMutation = `
    mutation cartNoteUpdate($cartId: ID!, $note: String!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
      cartNoteUpdate(cartId: $cartId, note: $note) {
        cart {
          ...CartFields
        }
        userErrors {
          field
          message
        }
      }
    }      
    ${cartFragment}
  `

  try {
    const variables = {
      cartId,
      note,
      country: countryCode,
    }

    const { data } = await shopifyClient.request(updateGiftMessageMutation, {
      variables,
    })

    const { cart, userErrors } = data.cartNoteUpdate

    if (userErrors && userErrors.length > 0) {
      console.error(
        "User errors occurred while updating cart attributes:",
        userErrors,
      )
      return
    }

    dispatch(
      setState({
        cart,
      }),
    )
  } catch (error) {
    console.error("Error adding gift message to cart:", error)
  }
}

export const { setState, setLocalisedLineItem } = app.actions

export default app
