import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Link } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import OutsideClickHandler from "react-outside-click-handler"

import { Nav } from "./Nav"
import { setState } from "../../../ducks/sidebarSlice"

import LogoWhite from "../../../assets/images/OrchardPigLogoBlack.png"
import LogoBlack from "../../../assets/images/OrchardPigLogo.png"
import CartImg from "../../../assets/svg/shoppingCart.inline.svg"

import { Burger } from "./Burger"
import Menu from "../menu/Menu"
import CallOut from "./CallOut"

import { Props } from "./Header.interface"

import * as s from "./Header.module.scss"

const Header = ({
  pageData,
  template,
  showMenu,
  setShowMenu,
  location,
}: Props) => {
  const { mainMenu, callout } = useStaticQuery(graphql`
    query HeaderQuery {
      mainMenu: wpMenu(slug: { eq: "main" }) {
        menuItems {
          nodes {
            url
            label
            order
          }
        }
      }
      callout: wpPage(slug: { eq: "shop" }) {
        acfShop {
          calloutMessage
        }
      }
    }
  `)
  const dispatch = useDispatch()

  const [offTop, setOffTop] = useState(false)
  const [isScrollingDown, setIsScrollingDown] = useState(false)
  const Scrolling = offTop || isScrollingDown

  const isShopPage = template === "Shop"
  const isChildShopPage =
    template == "Shop" && /shop\/(?=[^*])/.test(location.pathname)
  const cart = useSelector(state => state.cart.cart)
  const hasPassedAgeGate = useSelector(state => state.agegate.havePassed)

  const cartCount =
    cart && cart.lines?.edges.length > 0
      ? cart.lines?.edges
          .map(({ node }) => node.quantity)
          .reduce((total, node) => {
            return node + total
          })
      : 0

  if (typeof window !== `undefined`) {
    useScrollPosition(({ prevPos, currPos }) => {
      if (currPos.y >= 0) {
        setOffTop(false)
        return
      } else if (currPos.y > prevPos.y) {
        if (isScrollingDown) setIsScrollingDown(false)
      } else {
        if (!isScrollingDown && !showMenu) setIsScrollingDown(true)
      }

      if (currPos.y >= 0) {
        return
      } else {
        if (!offTop) setOffTop(true)
      }
    })
  }

  useEffect(() => {
    setOffTop(false)
    setIsScrollingDown(false)
  }, [pageData])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (showMenu) {
          setShowMenu(false)
        }
      }}
    >
      <div
        className={`${s.Header} 
      ${hasPassedAgeGate && s.Header___ageGateClosed}
      ${showMenu && hasPassedAgeGate && s.Header___menuActive}
      ${offTop && hasPassedAgeGate && s.Header___offTop}
      ${isScrollingDown && s.Header___scrollingDown}
      `}
      >
        <div
          className={s.Header__inner}
          style={{
            boxShadow:
              isChildShopPage || isScrollingDown || offTop
                ? "0px 0px 15px rgba(0,0,0,0.2)"
                : "0",
          }}
        >
          <Nav
            isOpen={showMenu}
            offTop={offTop}
            location={location}
            isChildShopPage={isChildShopPage}
            isScrollingDown={isScrollingDown}
            navItems={mainMenu.menuItems}
          />

          <div
            className={s.Header__logoWrapper}
            onClick={() => {
              setShowMenu(false)
            }}
          >
            <Link to="/">
              <img
                alt="Orchard Pig Site Logo"
                style={{
                  width: "90px",
                  height: "auto",
                  display:
                    Scrolling || showMenu || isChildShopPage ? "block" : "none",
                }}
                src={LogoWhite}
                width={172}
                height={100}
              />
              <img
                alt="Orchard Pig Site Logo"
                style={{
                  width: "90px",
                  height: "auto",
                  display:
                    Scrolling || showMenu || isChildShopPage ? "none" : "block",
                }}
                src={LogoBlack}
                width={172}
                height={100}
              />
            </Link>
          </div>

          <Burger
            setShowMenu={setShowMenu}
            showMenu={showMenu}
            isChildShopPage={isChildShopPage}
            scroll={Scrolling}
            active={showMenu}
          />

          <div className={s.Header__buttonGroup}>
            <div
              className={`${s.Header__buttonGroup___cart} ${
                cartCount > 0 && s.Header__buttonGroup___cart___hasItems
              } ${isChildShopPage && !Scrolling && s.Header__buttonGroupDark}`}
              onClick={() => {
                dispatch(
                  setState({
                    sidebarIsVisible: true,
                    content: "CART",
                  }),
                )
              }}
            >
              <CartImg />

              <div>
                <span>{cartCount}</span>
              </div>
            </div>
          </div>
        </div>

        {isShopPage && !isChildShopPage && callout.acfShop && (
          <CallOut className={s.Header__Calllout} data={callout.acfShop} />
        )}

        <Menu
          isOpen={showMenu}
          mainMenu={mainMenu}
          template={template}
          currentPage={pageData}
          setShowMenu={setShowMenu}
          location={location}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default Header
