import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "gatsby"

import * as l from "../SideBar.module.scss"
import * as s from "./Cart.module.scss"

import { initializeCart, addLineItemsToCart } from "../../../../ducks/cartSlice"
import { setState, openUpsell } from "../../../../ducks/sidebarSlice"
import { formatShopifyPrice } from "../../../../utils/formatShopifyPrice"

import CartIcon from "../../../../assets/svg/shoppingCart.inline.svg"

import { Cta } from "../../../common/cta/Cta"
import { QuantitySelector } from "../../quantity-selector/QuantitySelector"
import { CheckBox } from "../../../common/form/CheckBox/CheckBox"

const Cart = () => {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.cart)
  const checkoutTransition = useSelector(
    state => state.sidebar.checkoutTransition,
  )

  const [isOver18, setIsOver18] = useState(false)
  const [isOver18Error, setIsOver18Error] = useState(false)

  const openUpSell = () => {
    if (!isOver18) {
      setIsOver18Error(true)
      return
    } else {
      setIsOver18Error(false)
    }

    dispatch(openUpsell())
  }

  useEffect(() => {
    dispatch(initializeCart())
  }, [])

  useEffect(() => {
    if (isOver18) {
      setIsOver18Error(false)
    }
  }, [isOver18])

  return (
    <>
      <div className={l.Content__header}>
        <h3>Your Cart</h3>
      </div>

      {cart && (
        <div className={s.Cart}>
          {cart.lines && cart.lines.edges.length < 1 ? (
            <EmptyCart />
          ) : (
            <>
              <div>
                {cart.lines?.edges.map(({ node }, i) => {
                  return (
                    <div className={s.CartItem} key={i}>
                      <div
                        className={s.CartItem__image}
                        style={{
                          backgroundImage: `url(${node.merchandise?.image.url})`,
                        }}
                      />

                      <div className={s.CartItem__details}>
                        <h4>{node.merchandise.product.title}</h4>
                        <span>{node.merchandise.title}</span>

                        {node.attributes.length > 0 && (
                          <span>
                            <i>{node.attributes[0].value}</i>
                          </span>
                        )}
                      </div>

                      <div className={s.CartItem__options}>
                        <h4>
                          {formatShopifyPrice(node.merchandise.price.amount)}
                        </h4>

                        <QuantitySelector
                          quantity={node.quantity}
                          updateQuantity={val => {
                            const newQuantity = node.quantity + val

                            dispatch(
                              addLineItemsToCart({
                                product: {
                                  shopifyId: node.merchandise.id,
                                  quantity: newQuantity,
                                },
                                cartId: cart.id,
                              }),
                            )
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div>
                {cart.lines && cart.lines.edges.length > 0 && (
                  <div className={`${s.Cart__18check}`}>
                    <div>
                      <h4
                        onClick={() => {
                          setIsOver18(true)
                        }}
                        className={
                          isOver18Error ? s.Cart__18check___errorText : null
                        }
                      >
                        Please check the box to confirm you are over the age of
                        18
                      </h4>
                      <p>
                        Sale of alcohol is prohibited to those aged under 18 and
                        we reserve the right to cancel your order if no ID is
                        present at delivery. Full T&Cs{" "}
                        <Link to="terms-and-conditions">here</Link>.
                      </p>
                    </div>

                    <CheckBox
                      className={`${s.Cart__18check__box}`}
                      value={isOver18}
                      onChange={val => setIsOver18(val)}
                      error={isOver18Error}
                    />
                  </div>
                )}

                <div className={s.CartTotals}>
                  <div className={s.CartTotals__row}>
                    <p>Subtotal</p>
                    <h5>
                      {formatShopifyPrice(cart.cost?.subtotalAmount.amount)}
                    </h5>
                  </div>
                </div>
              </div>
            </>
          )}

          {cart && cart.lines?.edges.length > 0 && (
            <div className={`${s.Cart__buttons} ${s.Cart__buttons___double}`}>
              <div>
                <Cta
                  title="Continue Shopping"
                  className={s.Cart__button___secondary}
                  onClick={() => {
                    dispatch(
                      setState({
                        sidebarIsVisible: false,
                      }),
                    )
                  }}
                />
              </div>

              <div>
                <Cta title="Checkout" onClick={openUpSell} />
              </div>
            </div>
          )}
        </div>
      )}

      <div
        className={`${s.Transition} ${
          checkoutTransition && s.Transition___transitioning
        }`}
      ></div>
    </>
  )
}

const EmptyCart = () => {
  const dispatch = useDispatch()

  return (
    <div className={s.Cart__empty}>
      <div className={s.Cart__empty__content}>
        <CartIcon />
        <p>Hmm... it looks like you haven't added anything to your cart yet.</p>
      </div>

      <div className={s.Cart__buttons}>
        <div>
          <Cta
            title="Shop"
            link="shop"
            onClick={() => {
              dispatch(
                setState({
                  sidebarIsVisible: false,
                }),
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Cart
